import { gql } from '@apollo/client'

const QueryData = gql`
  query rooms($parentId: [ID!]) {
    viewer {
      accessibleUnits(parentUnitIds: $parentId) {
        nodes {
          wiredLocks(allowedActingDevices: ["identification_code", "nfc_card"]) {
            nodes {
              ... on LockControlPort {
                  id
                  name
              }
              ... on TwoWireLock {
                  id
                  name
              }
            }
          }
        }
      }
    }
  }
`
export default QueryData
